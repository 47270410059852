import * as React from 'react';
import {useState} from 'react';
import {toast} from "react-hot-toast";
import {MdPerson} from "react-icons/md";
import Zoom from "react-reveal/Zoom";
import firebase from "gatsby-plugin-firebase";
import * as queryString from "query-string";
import {navigate} from "gatsby";
import FieldEmail from "../form/fieldEmail";
import Field from "../form/field";
import FieldPassword from "../form/fieldPassword";
import ButtonSubmit from "../form/ButtonSubmit";
import {firebaseAuthErrMsg} from "../../util/firebaseUtil";
import {useTranslation} from "react-i18next";

type Props = {
    show: boolean;
}
const Register: React.FC<Props> = ({show}) => {
    const [regEmail, setRegEmail] = useState("")
    const [regPass, setRegPass] = useState("")
    const [regName, setRegName] = useState("")
    const {t} = useTranslation(["login", "errors"])

    async function createUserWithPassword(email, password, name) {
        let {user} = await firebase.auth().createUserWithEmailAndPassword(email, password)
        await user.updateProfile({displayName: name})
        let parsed = queryString.parse(location.search)
        let redirect = parsed.redirect as string;
        if (redirect == null) redirect = "/"
        await navigate(redirect);
    }

    return (<Zoom collapse when={show}>
        <div>
            <form onSubmit={(e) => {
                console.log("prevented", e.isDefaultPrevented());
                e.preventDefault();
                console.log("prevented", e.isDefaultPrevented());
                toast.promise(createUserWithPassword(regEmail, regPass, regName), {
                    loading: t("login:registerInProgress"),
                    success: t("login:registerSuccess"),
                    error: (err) => t(firebaseAuthErrMsg(err.code, err.message))
                })
            }}>
                <div className={`flex justify-center`}>
                    <div
                        className="flex flex-col  h-full w-2/3 sm:w-1/2 md:w-1/4 justify-center items-center space-y-4">
                        <Field icon={MdPerson} type="text" placeholder={t("login:fieldName")} value={regName}
                               onChange={(e) => setRegName(e.target.value)}/>
                        <FieldEmail value={regEmail} onChange={(e) => setRegEmail(e.target.value)}/>
                        <FieldPassword value={regPass} onChange={(e) => setRegPass(e.target.value)}/>
                        <ButtonSubmit text={t("login:register")}/>
                    </div>
                </div>
            </form>
        </div>
    </Zoom>)
}

export default Register;
