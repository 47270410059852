import * as React from 'react';
import {useState} from 'react';
import {toast} from "react-hot-toast";
import Zoom from "react-reveal/Zoom";
import firebase from "gatsby-plugin-firebase";
import * as queryString from "query-string";
import {navigate} from "gatsby";
import FieldEmail from "../form/fieldEmail";
import FieldPassword from "../form/fieldPassword";
import ButtonSubmit from "../form/ButtonSubmit";
import {firebaseAuthErrMsg} from "../../util/firebaseUtil";
import {useTranslation} from "react-i18next";

type Props = {
    show: boolean;
}
const SignIn: React.FC<Props> = ({show}) => {
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const {t} = useTranslation(["login", "errors"])

    async function loginWithPassword(email, password) {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        let parsed = queryString.parse(location.search)
        let redirect = parsed.redirect as string;
        if (redirect == null) redirect = "/"
        await navigate(redirect);
    }

    return (<Zoom collapse when={show}>
        <div>
            <form onSubmit={(e) => {
                console.log("prevented", e.isDefaultPrevented());
                e.preventDefault();
                console.log("prevented", e.isDefaultPrevented());
                toast.promise(loginWithPassword(email, pass), {
                    loading: t("login:loginInProgress"),
                    success: t("login:loginSuccess"),
                    error: (err) => t(firebaseAuthErrMsg(err.code, err.message))
                })
            }}>
                <div className={`flex justify-center`}>
                    <div
                        className="flex flex-col items-center h-full w-2/3 sm:w-1/2 md:w-1/4 justify-center space-y-4">
                        <FieldEmail value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <FieldPassword value={pass} onChange={(e) => setPass(e.target.value)}/>
                        <ButtonSubmit text={t("login:login")}/>
                    </div>
                </div>
            </form>
        </div>
    </Zoom>)
}

export default SignIn;
