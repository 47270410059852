import { MdLock, MdVisibility, MdVisibilityOff } from "react-icons/md";
import * as React from "react";
import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  value: string | ReadonlyArray<string> | number | undefined;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
};

const FieldPassword: React.FC<Props> = ({ value, onChange }) => {
  const [showPass, setShowPass] = useState(false);
  const { t } = useTranslation("login");
  return (
    <div className="h-10 flex w-full bg-white rounded-lg">
      <div className="flex flex-col justify-center items-center pl-1">
        <p className="text-blue-100 text-center align-middle text-xl">
          <MdLock />
        </p>
      </div>
      <input
        type={showPass ? "text" : "password"}
        placeholder={t("fieldPassword")}
        className="qstext pl-2 w-full bg-transparent text-blue-100 focus:outline-none"
        value={value}
        onChange={onChange}
      />
      <button
        type="button"
        aria-label="Show Password"
        className="cursor-pointer flex flex-col justify-center items-center select-none pr-1"
        onClick={() => setShowPass(!showPass)}
      >
        <p className="text-blue-100 pr-1 align-middle text-center text-xl">
          {showPass ? <MdVisibilityOff /> : <MdVisibility />}
        </p>
      </button>
    </div>
  );
};
export default FieldPassword;
