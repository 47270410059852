import * as React from "react";
import { ChangeEventHandler } from "react";
import { IconType } from "react-icons";

type Props = {
  icon: IconType;
  type: string;
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const Field = React.forwardRef<HTMLInputElement, Props>(
  ({ icon, type, placeholder, value, onChange, ...rest }, ref) => (
    <div className="h-10 flex bg-white rounded-lg w-full">
      <div className="flex flex-col justify-center items-center pl-1">
        <p className="text-blue-100 text-center align-middle text-xl">{React.createElement(icon)}</p>
      </div>
      <input
        type={type}
        placeholder={placeholder}
        className="qstext pl-2 w-full bg-transparent text-blue-100 focus:outline-none"
        value={value}
        onChange={onChange}
        ref={ref}
        {...rest}
      />
    </div>
  )
);
Field.displayName = "Field";
export default Field;
