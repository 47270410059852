import * as React from "react";
import { IoMdLogIn } from "react-icons/io";

type Props = {
  text: string;
};

const ButtonSubmit: React.FC<Props> = ({ text }) => (
  <button
    className={`flex min-w-1/2 justify-center items-center text-white text-xl cursor-pointer min-h-8 px-2 rounded-xl
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-orange px-4`}
    type="submit"
  >
    <span className="flex flex-1 m-auto items-center justify-center">
      <IoMdLogIn className="w-5 h-5" />
    </span>
    <span className="focus:outline-none mx-auto flex-grow">{text}</span>
    <span className="flex-1" />
  </button>
);

export default ButtonSubmit;
