import * as React from "react";
import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { MdEmail } from "react-icons/md";
import Field from "./field";

type Props = {
  value: string | ReadonlyArray<string> | number | undefined;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
};

const FieldEmail: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation("login");
  return <Field icon={MdEmail} type="email" placeholder={t("fieldEmail")} value={value} onChange={onChange} />;
};
export default FieldEmail;
