export function firebaseAuthErrMsg(errorCode: string, errorMessage?: string): string | null {
    switch (errorCode) {
        case 'auth/wrong-password':
            return "errors:wrongPassword";
        case 'auth/user-disabled':
            return "errors:userDisabled";
        case 'auth/user-not-found':
            return "errors:userNotFound";
        case 'auth/invalid-email':
            return "errors:invalidEmail";
        case 'auth/email-already-in-use':
            return "errors:emailAlreadyInUse";
        case 'auth/operation-not-allowed':
            return "errors:operationNotAllowed";
        case 'auth/weak-password':
            return "errors:weakPassword";
        default:
            return errorMessage;
    }
}
