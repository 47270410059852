import firebase from 'gatsby-plugin-firebase';
import {useEffect, useMemo} from 'react';
import useLoadingValue from "./loadingValue";

export type LoadingHook<T, E> = [T | undefined, boolean, E | undefined];

export type AuthStateHook = LoadingHook<firebase.User | null,
    firebase.auth.Error>;

const useStaticAuthState = (fb: (app?: firebase.app.App) => firebase.auth.Auth): AuthStateHook => {
    if(typeof fb !== 'function'){
        return [null, false, null];
    }
    const auth = fb();
    const {error, loading, setError, setValue, value} = useLoadingValue<firebase.User | null,
        firebase.auth.Error>(() => auth.currentUser);

    useEffect(() => {
        const listener = auth.onAuthStateChanged(setValue, setError);

        return () => {
            listener();
        };
    }, [auth]);

    const resArray: AuthStateHook = [value, loading, error];
    return useMemo<AuthStateHook>(() => resArray, resArray);
};

export default useStaticAuthState;
