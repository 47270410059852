import * as React from 'react';
import {useState} from 'react';
import {toast} from "react-hot-toast";
import FieldEmail from "../form/fieldEmail";
import firebase from "gatsby-plugin-firebase";
import Zoom from "react-reveal/Zoom";
import ButtonSubmit from "../form/ButtonSubmit";
import {firebaseAuthErrMsg} from "../../util/firebaseUtil";
import {useTranslation} from "react-i18next";

type Props = {
    show: boolean;
}

const ForgotPassword: React.FC<Props> = ({show}) => {
    const [email, setEmail] = useState("")
    const {t} = useTranslation(["login", "errors"])

    async function resetPassword(email) {
        await firebase.auth().sendPasswordResetEmail(email)
    }

    return (
        <Zoom collapse when={show}>
            <div>
                <form onSubmit={(e) => {
                    console.log("prevented", e.isDefaultPrevented());
                    e.preventDefault();
                    console.log("prevented", e.isDefaultPrevented());
                    toast.promise(resetPassword(email), {
                        loading: t("login:forgotPasswordInProgress"),
                        success: t("login:forgotPasswordSuccess"),
                        error: (err) => t(firebaseAuthErrMsg(err.code, err.message))
                    })
                }}>
                    <div className={`flex justify-center`}>
                        <div
                            className="flex flex-col items-center h-full w-2/3 sm:w-1/2 md:w-1/4 justify-center space-y-4">
                            <FieldEmail value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <ButtonSubmit text={t("login:resetPassword")}/>
                        </div>
                    </div>
                </form>
            </div>
        </Zoom>
    )
}

export default ForgotPassword;
