import * as React from "react";
import { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { navigate } from "gatsby";
import * as queryString from "query-string";
import Zoom from "react-reveal/Zoom";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import gIcon from "../images/icons/g-icon.svg";
import useStaticAuthState from "../hooks/staticAuthState";
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import SignIn from "../components/login/signin";
import Register from "../components/login/register";
import ForgotPassword from "../components/login/forgotPassword";
import { firebaseAuthErrMsg } from "../util/firebaseUtil";
import StandardToaster from "../components/standardToaster";

export default function Login({ location }) {
  const [user] = useStaticAuthState(firebase.auth);
  const [show, setShow] = useState("signin");
  const { t } = useTranslation(["login", "errors"]);
  useEffect(() => {
    async function goBack() {
      if (user != null) {
        console.log(location);
        const parsed = queryString.parse(location.search);
        console.log(parsed);
        let { redirect } = parsed;
        if (redirect == null) redirect = "/";
        await navigate(redirect, { replace: true });
      }
    }

    goBack();
  }, [user, location.search, location]);

  async function logInWithGoogle() {
    console.log(location);
    const parsed = queryString.parse(location.search);
    console.log(parsed);
    let { redirect } = parsed;
    if (redirect == null) redirect = "/";
    const provider = new firebase.auth.GoogleAuthProvider();
    await toast.promise(firebase.auth().signInWithPopup(provider), {
      loading: t("login:authGoogleInProgress"),
      success: t("login:authGoogleSuccess"),
      error: (err) => t(firebaseAuthErrMsg(err.code, err.message)),
    });
    await navigate(redirect, { replace: true });
  }

  return (
    <div className="flex flex-col h-full">
      <Seo title={t("title")} />
      <StandardToaster position="top-center" />
      <NavBar title={t("login:login")} />
      <div className="flex h-full justify-center flex-col items-center">
        <Zoom cascade>
          <div className="flex flex-col h-full w-full justify-center">
            <Register show={show === "register"} />
            <SignIn show={show === "signin"} />

            <Zoom collapse when={show === "signin" || show === "register"}>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={() => setShow((show) => (show === "signin" ? "register" : "signin"))}
                  className="cursor-pointer mt-4 text-white underline md:no-underline hover:underline text-sm text-bold text-center"
                >
                  {show === "register" ? t("login:goToLogin") : t("login:goToRegister")}
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={logInWithGoogle}
                  className="cursor-pointer mt-10 w-2/3 sm:w-1/2 md:w-1/5 transition duration-500 ease-in-out transform hover:scale-105 flex bg-white h-10 items-center rounded-lg"
                >
                  <img src={gIcon} alt="" className="flex-1 h-6" />
                  <p className="flex-grow text-blue-100 text-center text-xl text-extrabold">{t("login:authGoogle")}</p>
                  <div className="flex-1" />
                </button>
              </div>
            </Zoom>
            <ForgotPassword show={show === "forgot"} />
            <div className={`flex justify-center `}>
              <button
                type="button"
                onClick={() => setShow((show) => (show === "forgot" ? "signin" : "forgot"))}
                className="cursor-pointer mt-4 text-white underline md:no-underline hover:underline text-sm text-bold text-center"
              >
                {show === "forgot" ? t("cancel") : t("login:forgotPassword")}
              </button>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
}
